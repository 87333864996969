import React from "react";
import { Tooltip } from "antd";
import { InfoCircleOutlined } from "@ant-design/icons";

import StatBarChart from "./StatBarChart";

import styles from "../../components/stats/stats.module.scss";

const StatCard = ({ data }) => {
  const { title, barData, topData, bottomData } = data;

  const topDataValue = topData.value;
  // Use this if tours projected variance is decimal value, e.g. 0.25
  // const topDataValue =
  //   topData.label === 'Projected Variance'
  //     ? `${topData.value * 100}%`
  //     : topData.value;

  const getTooltipText = (title) => {
    switch (title) {
      case "Tours":
        return "Total number of property tours conducted and a tour projection for the remainder of the year (if tours continue at the same rate). A positive variance indicates a variance over the goal";
      case "Tour Conversion":
        return "Ratio of tours that resulted in lease signings, showing the effectiveness of your property tours";
      case "Net Leases":
        return "Balance between new lease approvals and cancellations/denials, indicating overall leasing performance";
      case "Renewals":
        return "Comparison between renewed leases and notices to vacate, showing resident retention rates";
      default:
        return "";
    }
  };

  return (
    <div className={styles.statCard}>
      <div className={styles.statCardTitle}>
        {title}
        <Tooltip title={getTooltipText(title)} placement="top">
          <InfoCircleOutlined className={styles.infoIcon} />
        </Tooltip>
      </div>

      <StatBarChart data={barData} name={title} />

      <div className={styles.statCardTopSection}>
        <div className={styles.statCardSection}>
          <label>{topData.label}</label>
          <div className={styles.statCardValue}>{topDataValue}</div>
        </div>
      </div>
      <div className={styles.statCardBottomSection}>
        <div className={styles.statCardSection}>
          <label>{bottomData.label}</label>
          <div className={styles.statCardValue}>{bottomData.value}</div>
        </div>
      </div>
    </div>
  );
};

export default StatCard;
