import { useEffect, useState } from "react";

import { Select } from "antd";

import ForecastContainer from "../../components/stats/ForecastContainer";
import GPR from "../../components/stats/GPR";
import LostLeases from "../../components/stats/LostLeases";
import Renewals from "../../components/stats/Renewals";
import Tours from "../../components/stats/Tours";
import TourConversion from "../../components/stats/TourConversion";

import { useAppSelector } from "../../store/hooks";

import styles from "./dashboard.module.scss";

const Dashboard = (): ReactNode => {
  const { userProperty }: any = useAppSelector((state) => state.user);

  const [propertyOptions, setPropertyOptions] = useState([]);
  const [selectedPropID, setSelectedPropID] = useState("");
  // console.log('selectedPropID:', selectedPropID);

  useEffect(() => {
    if (userProperty.length > 0) {
      const propertyOptions = userProperty.map((propty: any) => ({
        value: propty.property_id,
        label: propty.property_name,
      }));
      setPropertyOptions(propertyOptions);
      setSelectedPropID(userProperty[0].property_id);
    }
  }, [userProperty]);

  const handlePropertyChange = (propID: string) => {
    setSelectedPropID(propID);
  };

  return (
    <div className={styles.dashboard}>
      <div className={styles.heading}>Dashboard</div>

      <div className={styles.filters}>
        <Select
          placeholder="Select Property"
          value={selectedPropID}
          options={propertyOptions}
          onChange={handlePropertyChange}
          style={{ width: 320, fontFamily: "Poppins, sans-serif" }}
        />
      </div>

      <div className={styles.sectionHeading}>Current Year</div>
      <div className={styles.topSection}>
        <GPR propertyID={selectedPropID} />
        <ForecastContainer propertyID={selectedPropID} />
      </div>
      <div className={styles.sectionHeading}>YTD</div>
      <div className={styles.bottomSection}>
        <Tours propertyID={selectedPropID} />
        <TourConversion propertyID={selectedPropID} />
        <LostLeases propertyID={selectedPropID} />
        <Renewals propertyID={selectedPropID} />
      </div>
    </div>
  );
};

export default Dashboard;
