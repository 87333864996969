import { AxiosError, AxiosResponse } from 'axios';
import { httpRequest } from './request';

// Add interface for tour data structure
interface TourData {
  Year: number;
  Month: number;
  Tours: number;
}

function getGoalsService(
  propertyID: string,
  token: string
): Promise<AxiosResponse | AxiosError> {
  return new Promise((resolve, reject) => {
    httpRequest(`goals/${propertyID}`, 'get', token, null, false)
      .then((res: AxiosResponse) => {
        resolve(res);
      })
      .catch((err: AxiosError) => {
        reject(err);
      });
  });
}

function createGoalsService(
  body: any,
  token: string
): Promise<AxiosResponse | AxiosError> {
  return new Promise((resolve, reject) => {
    httpRequest(`goals`, 'post', token, body)
      .then((res: AxiosResponse) => {
        resolve(res);
      })
      .catch((err: AxiosError) => {
        reject(err);
      });
  });
}

function updateGoalsService(
  body: any,
  token: string
): Promise<AxiosResponse | AxiosError> {
  return new Promise((resolve, reject) => {
    httpRequest(`goals`, 'put', token, body)
      .then((res: AxiosResponse) => {
        resolve(res);
      })
      .catch((err: AxiosError) => {
        reject(err);
      });
  });
}

function getGoalsDataService(
  propertyID: string,
  token: string
): Promise<AxiosResponse | AxiosError> {
  return new Promise((resolve, reject) => {
    httpRequest(`goals/data/${propertyID}`, 'get', token, null, false)
      .then((res: AxiosResponse) => {
        resolve(res);
      })
      .catch((err: AxiosError) => {
        reject(err);
      });
  });
}

function getPropertyDataService(
  propertyID: string,
  batch: string,
  token: string
): Promise<AxiosResponse | AxiosError> {
  return new Promise((resolve, reject) => {
    httpRequest(`goals/data/${propertyID}/${batch}`, 'get', token, null, false)
      .then((res: AxiosResponse) => {
        resolve(res);
      })
      .catch((err: AxiosError) => {
        reject(err);
      });
  });
}

function getForecastDataService(
  body: any,
  token: string
): Promise<AxiosResponse | AxiosError> {
  return new Promise((resolve, reject) => {
    httpRequest(`goals/data/forecast`, 'post', token, body)
      .then((res: AxiosResponse) => {
        resolve(res);
      })
      .catch((err: AxiosError) => {
        reject(err);
      });
  });
}

function getLastYearToursByMonthService(
  propertyID: string,
  token: string
): Promise<AxiosResponse | AxiosError> {
  return new Promise((resolve, reject) => {
    console.log('%c Service: Making API request for last year tours', 'color: blue; font-weight: bold', {
      propertyID,
      endpoint: `historical-data/tours/${propertyID}`
    });
    
    // Use the new endpoint route
    httpRequest(`historical-data/tours/${propertyID}`, 'get', token, null, false)
      .then((res: AxiosResponse) => {
        console.log('%c Service: Raw API response for tours', 'color: green; font-weight: bold', {
          data: res.data,
          dataType: typeof res.data,
          hasToursProperty: res.data && 'tours' in res.data,
          toursType: res.data && res.data.tours ? typeof res.data.tours : 'undefined',
          isToursArray: res.data && res.data.tours ? Array.isArray(res.data.tours) : false
        });
        
        // Check if response exists and has data
        if (!res || !res.data) {
          console.warn('%c Service: Empty or missing response data', 'color: orange; font-weight: bold');
          resolve({
            data: { tours: {} },
            status: 200,
            statusText: 'OK',
            headers: {},
            config: {}
          } as AxiosResponse);
          return;
        }

        // Check if response has tours object
        if (!res.data.tours) {
          console.warn('%c Service: Response missing tours object', 'color: orange; font-weight: bold');
          resolve({
            data: { tours: {} },
            status: 200,
            statusText: 'OK',
            headers: {},
            config: {}
          } as AxiosResponse);
          return;
        }

        // If tours is an array, transform it to the expected format
        if (Array.isArray(res.data.tours)) {
          console.log('%c Service: Converting tours array to object', 'color: blue; font-weight: bold', {
            originalArray: res.data.tours
          });
          
          const transformedTours = (res.data.tours as TourData[]).reduce((acc: Record<number, number>, tour: TourData) => {
            // Ensure tour has the expected properties
            if (tour && typeof tour.Month === 'number' && typeof tour.Tours === 'number') {
              // Month is 1-based in the API, but we want 0-based for our object
              const monthIndex = tour.Month - 1;
              acc[monthIndex] = tour.Tours;
            }
            return acc;
          }, {});
          
          console.log('%c Service: Transformed tours data', 'color: green; font-weight: bold', {
            transformedTours
          });
          
          resolve({
            ...res,
            data: { tours: transformedTours }
          } as AxiosResponse);
          return;
        }
        
        // If tours is an object with month names as keys, transform it to month indices
        const tours = res.data.tours;
        const monthNameToIndex: Record<string, number> = {
          jan: 0, feb: 1, mar: 2, apr: 3, may: 4, jun: 5,
          jul: 6, aug: 7, sep: 8, oct: 9, nov: 10, dec: 11
        };
        
        const transformedTours: Record<number, number> = {};
        
        for (const monthName in tours) {
          const monthKey = monthName.toLowerCase();
          if (monthNameToIndex.hasOwnProperty(monthKey)) {
            const monthIndex = monthNameToIndex[monthKey];
            const tourValue = Number(tours[monthName]);
            if (!isNaN(tourValue)) {
              transformedTours[monthIndex] = tourValue;
            } else {
              console.warn(`%c Service: Invalid tour value for month ${monthName}`, 'color: orange; font-weight: bold', tours[monthName]);
              transformedTours[monthIndex] = 0;
            }
          }
        }
        
        console.log('%c Service: Transformed tours data from month names to indices', 'color: green; font-weight: bold', {
          original: tours,
          transformed: transformedTours
        });
        
        resolve({
          ...res,
          data: { tours: transformedTours }
        } as AxiosResponse);
      })
      .catch((err: AxiosError) => {
        console.error('%c Service: Failed to fetch last year tours', 'color: red; font-weight: bold', {
          message: err.message,
          status: err.response?.status,
          statusText: err.response?.statusText,
          data: err.response?.data,
          url: err.config?.url
        });
        resolve({
          data: { tours: {} },
          status: 200,
          statusText: 'OK',
          headers: {},
          config: {}
        } as AxiosResponse);
      });
  });
}

function getLastYearConversionRatesService(
  propertyID: string,
  token: string
): Promise<AxiosResponse | AxiosError> {
  return new Promise((resolve, reject) => {
    console.log('%c Service: Starting Conversion Rates Request', 'color: blue; font-weight: bold');
    
    // Use the new endpoint route
    const endpoint = `historical-data/conversion-rates/${propertyID}`;
    
    console.log('Request Details:', {
      endpoint,
      propertyID,
      tokenPresent: !!token,
      tokenLength: token?.length
    });
    
    httpRequest(endpoint, 'get', token, null, false)
      .then((res: AxiosResponse) => {
        console.log('%c Service: Conversion Rates Success', 'color: green; font-weight: bold', {
          status: res.status,
          statusText: res.statusText,
          data: res.data
        });
        
        if (!res || !res.data || typeof res.data.tourConversionRate === 'undefined' || typeof res.data.netLeasesRate === 'undefined') {
          console.warn('%c Service: Invalid Response Structure', 'color: orange; font-weight: bold', {
            receivedData: res.data,
            expectedStructure: {
              tourConversionRate: 'number',
              netLeasesRate: 'number'
            }
          });
          resolve({
            data: {
              tourConversionRate: 0,
              netLeasesRate: 0
            },
            status: 200,
            statusText: 'OK',
            headers: {},
            config: {}
          } as AxiosResponse);
          return;
        }

        resolve(res);
      })
      .catch((err: AxiosError) => {
        console.log('%c Service: Conversion Rates Request FAILED', 'color: red; font-size: 16px; font-weight: bold');
        console.error('Error Details:', {
          message: err.message,
          status: err.response?.status,
          statusText: err.response?.statusText,
          data: err.response?.data,
          url: err.config?.url,
          method: err.config?.method,
          headers: {
            ...err.config?.headers,
            Authorization: err.config?.headers?.Authorization ? 'Bearer [REDACTED]' : 'Missing'
          }
        });

        resolve({
          data: {
            tourConversionRate: 0,
            netLeasesRate: 0
          },
          status: 200,
          statusText: 'OK',
          headers: {},
          config: {}
        } as AxiosResponse);
      });
  });
}

export {
  createGoalsService,
  getGoalsService,
  updateGoalsService,
  getGoalsDataService,
  getPropertyDataService,
  getForecastDataService,
  getLastYearToursByMonthService,
  getLastYearConversionRatesService
};
