import { useCallback, useEffect, useMemo, useState } from "react";
import { Form, notification, Select, Spin } from "antd";

import Forecast from "../../components/goals/Forecast";
import GoalsForm from "../../components/goals/GoalsForm";
import Loader from "../../components/goals/Loader";
import MetricsFin from "../../components/goals/MetricsFin";
import MetricsOp from "../../components/goals/MetricsOp";

import { useAppDispatch, useAppSelector } from "../../store/hooks";

import {
  createGoals,
  getForecastData,
  getGoals,
  getPropertyData1,
  getPropertyData2,
  getPropertyData3,
  getPropertyData4,
  getPropertyData5,
  setPrptyDataLoading,
  updateGoals,
} from "../../store/goals/goals-slice";

import styles from "./goals.module.scss";
import "../../components/goals/goalsForm.css";

type NotificationType = "success" | "info" | "warning" | "error";

const Goals = (): ReactNode => {
  const [form] = Form.useForm();

  const dispatch = useAppDispatch();
  const [api, contextHolder] = notification.useNotification();

  const {
    user: { name },
    userProperty,
  }: any = useAppSelector((state) => state.user);
  const { goals, isLoading: isGoalsLoading, pk_id }: any = useAppSelector((state) => state.goals);

  const isForecastDataLoading: boolean = useAppSelector(
    (state) => state.goals.isForecastDataLoading
  );

  const isPrptyDataLoading: boolean = useAppSelector((state) => state.goals.isPrptyDataLoading);

  const isLoading = useMemo(() => {
    return isGoalsLoading || isPrptyDataLoading || isForecastDataLoading;
  }, [isGoalsLoading, isPrptyDataLoading, isForecastDataLoading]);

  const [propertyOptions, setPropertyOptions] = useState([]);
  const [selectedPropID, setSelectedPropID] = useState("");

  const openNotification = (type: NotificationType, message: string, description: string) => {
    api[type]({
      message,
      description,
    });
  };

  const handlePropertyChange = (propID: string) => {
    setSelectedPropID(propID);
  };

  useEffect(() => {
    if (userProperty.length > 0) {
      const propertyOptions = userProperty.map((propty: any) => ({
        value: propty.property_id,
        label: propty.property_name,
      }));
      setPropertyOptions(propertyOptions);
      setSelectedPropID(userProperty[0].property_id);
    }
  }, [userProperty]);

  const fetchGoals = useCallback(
    async (propertyID: string) => {
      try {
        await dispatch(getGoals(propertyID));
      } catch (error) {
        console.log("fetchGoals/error:", error);
      }
    },
    [dispatch]
  );

  useEffect(() => {
    if (!selectedPropID) {
      return;
    }

    fetchGoals(selectedPropID);
  }, [fetchGoals, selectedPropID]);

  const fetchPropertyData = useCallback(
    async (propertyID: string) => {
      try {
        dispatch(setPrptyDataLoading(true));
        await dispatch(getPropertyData1(propertyID));
        await dispatch(getPropertyData2(propertyID));
        await dispatch(getPropertyData3(propertyID));
        await dispatch(getPropertyData4(propertyID));
        await dispatch(getPropertyData5(propertyID));
      } catch (error) {
        console.log("fetchPropertyData/error:", error);
      } finally {
        dispatch(setPrptyDataLoading(false));
      }
    },
    [dispatch]
  );

  useEffect(() => {
    if (!selectedPropID) {
      return;
    }

    fetchPropertyData(selectedPropID);
  }, [selectedPropID, fetchPropertyData]);

  const updateForecastData = useCallback(async () => {
    const fields = form.getFieldsValue(true);
    fields.propertyID = selectedPropID;

    dispatch(getForecastData(fields));
  }, [dispatch, form, selectedPropID]);

  useEffect(() => {
    if (!selectedPropID) {
      return;
    }

    updateForecastData();
  }, [goals, selectedPropID, updateForecastData]);

  const createPropertyGoals = async (data: any) => {
    try {
      const submitData = {
        manager_name: name,
        goals: { ...data },
        PropertyID: selectedPropID,
        year: new Date().getFullYear(),
      };

      const res: any = await dispatch(createGoals(submitData));

      if (res.payload.success) {
        openNotification("success", "Success", "Goals created successfully");
      } else {
        openNotification("error", "Error", "Goals creation failed");
      }
    } catch (error) {
      console.log("createPropertyGoals => error: ", error);
      openNotification("error", "Error", "Goals creation failed");
    }
  };

  const updatePropertyGoals = async (data: any) => {
    try {
      console.log("updatePropertyGoals => data: ", data);
      const submitData = {
        pk_id,
        manager_name: name,
        PropertyID: selectedPropID,
        goals: { ...data },
      };

      const res: any = await dispatch(updateGoals(submitData));

      if (res.payload.success) {
        openNotification("success", "Success", "Goals updated successfully");
      } else {
        openNotification("error", "Error", "Goals update failed");
      }
    } catch (error) {
      console.log("updatePropertyGoals => error: ", error);
      openNotification("error", "Error", "Goals update failed");
    }
  };

  const handleSubmit = async (values: any) => {
    try {
      if (pk_id) {
        updatePropertyGoals(values);
      } else {
        createPropertyGoals(values);
      }
    } catch (error) {
      console.log("handleSubmit => error:", error);
      const errMessage = pk_id ? "Error updating goals" : "Error creating goals";
      openNotification("error", errMessage, "Please try again or contact support.");
    }
  };

  return (
    <>
      <div id="goals-page" className={styles.goals}>
        {isLoading && <Loader />}
        <div className={styles.heading}>
          <span className={styles.headingTitle}>Projected Budget vs Actual</span>
        </div>

        <div className={styles.filters}>
          <Select
            value={selectedPropID}
            options={propertyOptions}
            onChange={handlePropertyChange}
            style={{ width: 320, fontFamily: "Poppins, sans-serif" }}
          />
        </div>

        <div className={styles.sectionHeading}>Current Year</div>

        <div className={styles.section}>
          <Forecast propertyID={selectedPropID} />
        </div>

        <div className={styles.section}>
          <div className={styles.sectionHeading}>Goals</div>
          <GoalsForm
            formInstance={form}
            handleFormChange={updateForecastData}
            onSubmit={handleSubmit}
            isUpdate={!!pk_id}
            propertyID={selectedPropID}
          />
        </div>
        <div className={styles.section}>
          <div className={styles.sectionHeading}>
            <span>Financial Metrics</span>
            {isForecastDataLoading && (
              <span className={styles.loader}>
                <Spin />
              </span>
            )}
          </div>
          <MetricsFin />
        </div>

        <div className={styles.section}>
          <div className={styles.sectionHeading}>Operational Metrics</div>
          <MetricsOp formInstance={form} />
        </div>
      </div>
      {contextHolder}
    </>
  );
};

export default Goals;
