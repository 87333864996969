/* eslint-disable react-hooks/exhaustive-deps */
import { useMsal } from "@azure/msal-react";
import { useAppDispatch, useAppSelector } from "../store/hooks";
import {
  asyncLogin,
  asyncGetMyRoles,
  asyncGetUserProperty,
  asyncGetUserProducts,
} from "../store/user/user-async";
import { AccountInfo } from "@azure/msal-browser";
import { useCallback, useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import axios from "axios";
import { logout } from "../store/user/user-slice";
import { Loader } from "../components";

interface IPrivateRoute {
  component: ReactNode;
}
export const axiosApiInstance = axios.create();

function PrivateRoute({
  component: Component,
  ...rest
}: IPrivateRoute): ReactNode {
  const { accounts, instance } = useMsal();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const { token, roles, userProperty } = useAppSelector((s) => s.user);
  const [loading, setLoading] = useState(true);
  const [rolesLoading, setRolesLoading] = useState(true);

  const getIDToken = async () => {
    // await dispatch(logout())
    const activeAccount: AccountInfo | any = instance.getAllAccounts()[0];
    if (!activeAccount) {
      setLoading(false);
      navigate("/");
      return;
    }
    try {
      const response = await instance.acquireTokenSilent({
        scopes: ["openid", "profile"],
        account: activeAccount,
      });
      return response?.idToken;
    } catch (error) {
      console.log(error, "=====> error acquireTokenSilent");
      setLoading(false);
      navigate("/");
    }
  };

  const getProducts = useCallback(async (): Promise<void> => {
    const newToken = token;
    if (newToken) {
      try {
        await dispatch(asyncGetUserProducts(newToken)).unwrap();
        setLoading(false);
      } catch (err) {
        console.log(err);
        setLoading(false);
      }
    }
  }, [dispatch, token]);

  useEffect(() => {
    getProducts();
  }, [getProducts]);

  useEffect(() => {
    getIDToken()
      .then((res): any => {
        if (res) {
          const token = res;
          const user = accounts[0];
          delete user.idToken;

          dispatch(asyncLogin({ user, token }))
            .then((res) => dispatch(asyncGetMyRoles(token)))
            .then((_) => dispatch(asyncGetUserProperty()))
            .then(() => setRolesLoading(false))
            .catch((err) => console.log(err, "Error"))
            .finally(() => setLoading(false));
        } else {
          setLoading(false);
        }
      })
      .catch((err) => {
        console.log(err, "Error");
        setLoading(false);
        navigate("/");
      });
  }, []);

  useEffect(() => {
    axiosApiInstance.interceptors.request.use(
      async (config) => {
        return config;
      },
      (error) => {
        return Promise.reject(error);
      }
    );
    axiosApiInstance.interceptors.response.use(
      (response) => {
        return response;
      },
      async (error) => {
        if (error.response.status === 401) {
          // logout();
          await instance.logoutRedirect();
          await dispatch(logout());
          navigate("/");
        }
        return Promise.reject(error);
      }
    );
  }, []);

  useEffect(() => {
    // console.log("Token status:", token);
    if (!token && !loading) {
      console.log("No token, navigating to home");
      navigate("/");
    }
  }, [token, navigate]);

  useEffect(() => {
    console.log("Pathname:", location.pathname);

    if (token && roles && !rolesLoading) {
      if (
        location.pathname === "/admin" &&
        !roles?.find((record: any) => record.role_name === "lux_admin")
      ) {
        console.log("Not an admin, navigating to home");
        navigate("/home");
      } else if (
        location.pathname === "/renewal" &&
        (!userProperty || !userProperty.length)
      ) {
        console.log("No user property, navigating to home");
        navigate("/home");
      }
    }
  }, [token, roles, userProperty, location.pathname, navigate, rolesLoading]);

  if (loading || rolesLoading) {
    return <Loader />;
  }

  if (token) {
    console.log("Token exists, rendering component");
    return <Component {...rest} />;
  } else {
    console.log("No token, returning null");
    return null;
  }
}

export default PrivateRoute;
