import React, { useCallback, useEffect } from 'react';
import { Button, Form, InputNumber, Slider } from 'antd';
import type { FormInstance } from 'antd';

import GoalsFormHeader from './GoalsFormHeader';

import { getFieldWithMonths, monthKeys } from '../../utils/months';

import { getGoalsInitialData } from '../../store/goals/goals-utils';
import { useAppSelector, useAppDispatch } from '../../store/hooks';
import { getLastYearToursByMonth, getLastYearConversionRates } from '../../store/goals/goals-slice';

import styles from './goalsForm.module.scss';
import './goalsForm.css';

interface GoalsFormProps {
  formInstance: FormInstance;
  handleFormChange: () => void;
  isUpdate?: boolean;
  onSubmit: (values: any) => void;
  propertyID: string;
}

interface LastYearTours {
  [key: string]: number;
}

const GoalsForm: React.FC<GoalsFormProps> = ({
  formInstance,
  handleFormChange,
  isUpdate,
  onSubmit,
  propertyID
}) => {
  const dispatch = useAppDispatch();
  const { goals, isLoading, isUpdating, lastYearTours, lastYearRates } = useAppSelector(
    (state) => state.goals
  ) as { 
    goals: any; 
    isLoading: boolean; 
    isUpdating: boolean; 
    lastYearTours: LastYearTours;
    lastYearRates: { tourConversionRate: number; netLeasesRate: number; }
  };

  console.log('GoalsForm rendered with propertyID:', propertyID);
  console.log('Current lastYearTours:', lastYearTours);

  const buttonLabel = isUpdate ? 'Update Goals' : 'Create Goals';
  const numberInputWidth = '2.5rem';

  useEffect(() => {
    if (propertyID) {
      console.log('Fetching last year tours for propertyID:', propertyID);
      dispatch(getLastYearToursByMonth(propertyID));
      dispatch(getLastYearConversionRates(propertyID));
    }
  }, [dispatch, propertyID]);

  const setFormValues = useCallback(
    (data: any) => {
      if (data) {
        const propGoals = {
          concessions: data.concessions || initialValues.goals.concessions,
          netLeasesPct: data.net_leases_percentage?.jan || initialValues.goals.netLeasesPct,
          renewalPct: data.ren_ntv_goal?.jan,
          tours: data.tours,
          tourConversionPct: data.tour_conversion_percentage?.jan
        };

        formInstance.setFieldsValue(propGoals);
      } else {
        formInstance.setFieldsValue(goalsFormInitialValues);
      }
    },
    [formInstance]
  );

  useEffect(() => {
    if (isLoading || !goals) {
      return;
    }
    setFormValues(goals);
  }, [goals, isLoading, setFormValues]);

  const handleFormReset = () => {
    setFormValues(goals);
  };

  const handleFormSubmit = (values: any) => {
    const data = {
      concessions: values.concessions,
      net_leases_percentage: getFieldWithMonths(values.netLeasesPct),
      ren_ntv_goal: getFieldWithMonths(values.renewalPct),
      tours: values.tours,
      tour_conversion_percentage: getFieldWithMonths(values.tourConversionPct)
    };
    onSubmit(data);
  };

  const initialValues = getGoalsInitialData();
  const goalsFormInitialValues = {
    concessions: initialValues.goals.concessions,
    netLeasesPct: initialValues.goals.netLeasesPct,
    tours: initialValues.goals.tours,
    tourConversionPct: initialValues.goals.tourConversionPct,
    renewalPct: initialValues.goals.renewalPct
  };

  return (
    <Form
      form={formInstance}
      onValuesChange={handleFormChange}
      onFinish={handleFormSubmit}
      initialValues={goalsFormInitialValues}
      className={styles.goalsForm}
    >
      <GoalsFormHeader />

      <div className={styles.row} data-row="odd" data-type="tours">
        <div className={styles.field} data-field="wide">
          <div className={styles.rowLabel} data-type="tours">Tours</div>
        </div>
        <div className={styles.lastYearToursRow}>
          <div className={styles.field} data-field="wide">
            <div className={styles.lastYearLabel}>{new Date().getFullYear() - 1} Tours</div>
          </div>
          {monthKeys.map((month) => (
            <div key={`last-year-${month}`} className={styles.field}>
              <div className={styles.lastYearValue}>
                {lastYearTours[monthKeys.indexOf(month)] || 0}
              </div>
            </div>
          ))}
        </div>
        <div className={styles.toursInputRow}>
          <div className={styles.field} data-field="wide">
            <div className={styles.rowLabel} data-type="tours">Tour Goals</div>
          </div>
          {monthKeys.map((month) => (
            <div key={month} className={styles.field}>
              <Form.Item name={['tours', month]} className={styles.formItem}>
                <InputNumber
                  style={{ width: numberInputWidth }}
                  min={0}
                  max={999}
                  placeholder="0"
                />
              </Form.Item>
            </div>
          ))}
        </div>
      </div>

      <div className={styles.row} data-row="even" data-type="input">
        <div className={styles.field} data-field="wide">
          <div className={styles.rowLabel} data-fontsize="smaller">
            Concession Weeks Free
          </div>
        </div>
        {monthKeys.map((month) => (
          <div key={month} className={styles.field}>
            <Form.Item name={['concessions', month]} className={styles.formItem}>
              <InputNumber
                style={{ width: numberInputWidth }}
                min={0}
                max={100}
              />
            </Form.Item>
          </div>
        ))}
      </div>

      <div id="tourConversionPct" className={styles.row} data-row="odd" data-type="input">
        <div className={styles.field} data-field="slider">
          <div className={styles.rowLabel}>Conversion %</div>
          <div className={styles.lastYearLabel}>
            {new Date().getFullYear() - 1}: {lastYearRates.tourConversionRate}%
          </div>
        </div>
        <div className={styles.sliderWrapper}>
          <Form.Item name="tourConversionPct" noStyle>
            <Slider min={0} max={100} />
          </Form.Item>
        </div>
        <Form.Item name="tourConversionPct" noStyle>
          <InputNumber min={0} max={100} formatter={(value) => `${value}%`} />
        </Form.Item>
      </div>

      <div id="renewalPct" className={styles.row} data-row="even" data-type="input">
        <div className={styles.field} data-field="slider">
          <div className={styles.rowLabel}>Renewal %</div>
        </div>
        <div className={styles.sliderWrapper}>
          <Form.Item name="renewalPct" noStyle>
            <Slider min={0} max={100} />
          </Form.Item>
        </div>
        <Form.Item name="renewalPct" noStyle>
          <InputNumber min={0} max={100} formatter={(value) => `${value}%`} />
        </Form.Item>
      </div>

      <div id="netLeasesPct" className={styles.row} data-row="odd" data-type="input">
        <div className={styles.field} data-field="slider">
          <div className={styles.rowLabel}>Net Leases %</div>
          <div className={styles.lastYearLabel}>
            {new Date().getFullYear() - 1}: {lastYearRates.netLeasesRate}%
          </div>
        </div>
        <div className={styles.sliderWrapper}>
          <Form.Item name="netLeasesPct" noStyle>
            <Slider min={0} max={100} />
          </Form.Item>
        </div>
        <Form.Item name="netLeasesPct" noStyle>
          <InputNumber min={0} max={100} formatter={(value) => `${value}%`} />
        </Form.Item>
      </div>

      <div className={styles.row} data-row="bottom">
        <Form.Item className={styles.btnWrapper}>
          <Button
            type="default"
            htmlType="button"
            className={styles.cancelBtn}
            onClick={handleFormReset}
          >
            Cancel
          </Button>
          <Button
            type="primary"
            htmlType="submit"
            loading={isUpdating}
            className={styles.submitBtn}
            disabled={isLoading}
          >
            {buttonLabel}
          </Button>
        </Form.Item>
      </div>
    </Form>
  );
};

export default GoalsForm;
