import { ColumnsType } from "antd/es/table";

export const columns1 = (type: string): ColumnsType<IPricingData> => {
  return [
    {
      title: "Unit Number",
      dataIndex: "UnitNumber",
      key: "UnitNumber",
      sorter: (a, b) => {
        const unitA = a.UnitNumber.toLowerCase();
        const unitB = b.UnitNumber.toLowerCase();

        if (unitA < unitB) return -1;
        if (unitA > unitB) return 1;
        return 0;
      },
    },
    {
      title: "Floor Plan",
      dataIndex: "UnitType",
      key: "UnitType",
      sorter: (a, b) => {
        const unitA = a.UnitType.toLowerCase();
        const unitB = b.UnitType.toLowerCase();

        if (unitA < unitB) return -1;
        if (unitA > unitB) return 1;
        return 0;
      },
    },
    {
      title: "Bedrooms",
      dataIndex: "Bedrooms",
      key: "Bedrooms",
      sorter: (a, b) => parseInt(a.Bedrooms, 10) - parseInt(b.Bedrooms, 10),
    },
    {
      title: "Current Price",
      dataIndex: "MarketRent",
      key: "MarketRent",
      render: (text) =>
        parseFloat(text) < 0
          ? `-$${new Intl.NumberFormat("en-US").format(Math.abs(text))}`
          : `$${new Intl.NumberFormat("en-US").format(text)}`,
      sorter: (a, b) => parseInt(a.MarketRent, 10) - parseInt(b.MarketRent, 10),
    },
    {
      title: type === "vacant" ? "Days Vacant" : "Days Until Vacant",
      dataIndex: "DaysUntilAvailable",
      key: "DaysUntilAvailable",
      sorter: (a, b) => a.DaysUntilAvailable - b.DaysUntilAvailable,
      render(value) {
        return type === "vacant" ? Math.abs(value) : value;
      },
    },
    {
      title: "Calculated Market Rent",
      dataIndex: "CalculatedMarketRent",
      key: "CalculatedMarketRent",
      render: (text) =>
        parseFloat(text) < 0
          ? `-$${new Intl.NumberFormat("en-US").format(
              Math.abs(Number(Number(text).toFixed(0)))
            )}`
          : `$${new Intl.NumberFormat("en-US").format(
              Number(Number(text).toFixed(0))
            )}`,
      sorter: (a, b) => a.CalculatedMarketRent - b.CalculatedMarketRent,
    },
    {
      title: "Suggested Price",
      dataIndex: "FinalPrice",
      key: "FinalPrice",
      render: (text) =>
        parseFloat(text) < 0
          ? `-$${new Intl.NumberFormat("en-US").format(
              Math.abs(Number(Number(text).toFixed(2)))
            )}`
          : `$${new Intl.NumberFormat("en-US").format(
              Number(Number(text).toFixed(2))
            )}`,
      sorter: (a, b) => a.FinalPrice - b.FinalPrice,
    },
    {
      title: "Adjustment Amount",
      dataIndex: "PriceAdjustment",
      key: "PriceAdjustment",
      render: (text) =>
        parseFloat(text) < 0
          ? `-$${new Intl.NumberFormat("en-US").format(
              Math.abs(Number(Number(text).toFixed(2)))
            )}`
          : `$${new Intl.NumberFormat("en-US").format(
              Number(Number(text).toFixed(2))
            )}`,
      sorter: (a, b) => a.PriceAdjustment - b.PriceAdjustment,
    },
  ];
};
