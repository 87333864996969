import { useCallback, useEffect, useMemo } from "react";

import Forecast from "../goals/Forecast";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import {
  getPropertyData1,
  getPropertyData2,
  getPropertyData3,
  getPropertyData4,
  getPropertyData5,
  setPrptyDataLoading,
  getGoals,
  getForecastData,
  updateMoveInsForecast,
  updateMoveOutsForecast,
} from "../../store/goals/goals-slice";
import { getGoalsInitialData } from "../../store/goals/goals-utils";

const initialValues = getGoalsInitialData();

interface componentProps {
  propertyID: string;
}

const ForecastContainer = ({ propertyID }: componentProps): ReactNode => {
  const dispatch = useAppDispatch();

  const { goals } = useAppSelector((state) => state.goals);
  const expiringLeases: any = useAppSelector((state) => state.goals.propertyData.expiringLeases);

  const goalsData = useMemo(
    () =>
      goals
        ? {
            concessions: goals.concessions ? goals.concessions : initialValues.goals.concessions,
            netLeasesPct: goals.net_leases_percentage?.jan
              ? goals.net_leases_percentage.jan
              : initialValues.goals.netLeasesPct,
            renewalPct: goals.ren_ntv_goal.jan,
            tours: goals.tours,
            tourConversionPct: goals.tour_conversion_percentage.jan,
          }
        : null,
    [goals]
  );

  const fetchPropertyData = useCallback(
    async (propertyId: string) => {
      try {
        dispatch(setPrptyDataLoading(true));
        await dispatch(getPropertyData1(propertyId));
        await dispatch(getPropertyData2(propertyId));
        await dispatch(getPropertyData3(propertyId));
        await dispatch(getPropertyData4(propertyId));
        await dispatch(getPropertyData5(propertyId));
      } catch (error) {
        console.log("fetchPropertyData/error:", error);
      } finally {
        dispatch(setPrptyDataLoading(false));
      }
    },
    [dispatch]
  );

  useEffect(() => {
    if (!propertyID) return;

    fetchPropertyData(propertyID);
  }, [propertyID, fetchPropertyData]);

  const fetchGoals = useCallback(
    async (propertyId: string) => {
      try {
        await dispatch(getGoals(propertyId));
      } catch (error) {
        console.log("fetchGoals/error:", error);
      }
    },
    [dispatch]
  );

  useEffect(() => {
    if (!propertyID) return;

    fetchGoals(propertyID);
  }, [propertyID, fetchGoals]);

  const fetchForecastData = useCallback(
    async (payload: any) => {
      try {
        await dispatch(getForecastData(payload));
      } catch (error) {
        console.log("fetchForecastData/error:", error);
      }
    },
    [dispatch]
  );

  useEffect(() => {
    if (!propertyID || !goalsData) return;

    fetchForecastData({ propertyID, ...goalsData });
  }, [propertyID, goalsData, fetchForecastData]);

  useEffect(() => {
    if (!goalsData) return;

    dispatch(
      updateMoveInsForecast({
        netLeasesPctGoal: goalsData.netLeasesPct,
        tourGoals: goalsData.tours,
        tourConversionPctGoal: goalsData.tourConversionPct,
      })
    );
  }, [dispatch, goalsData]);

  useEffect(() => {
    if (!goalsData || goalsData.renewalPct === 0 || !expiringLeases) return;

    dispatch(
      updateMoveOutsForecast({
        expiringLeases,
        renewalPctGoal: goalsData.renewalPct,
      })
    );
  }, [dispatch, goalsData, expiringLeases]);

  return <Forecast propertyID={propertyID} showERRCard={false} />;
};

export default ForecastContainer;
