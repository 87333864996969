import { useNavigate } from 'react-router-dom';

import MenuItem from './MenuItem';

import admin from '../../assets/images/admin.svg';
import goalsIcon from '../../assets/images/income.svg';
import renewal from '../../assets/images/renewal.svg';
import prospect from '../../assets/images/prospect.svg';

import { useAppDispatch, useAppSelector } from '../../store/hooks';
import { setRenewal } from '../../store/renewal/renewal-slice';

import styles from './sidebar.module.scss';

interface componentProps {
  isSidebarOpen: boolean;
  pathname: string;
}

const Menu = ({ isSidebarOpen, pathname }: componentProps) => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const { userProducts, roles, userProperty }: any = useAppSelector(
    (state) => state.user
  );

  const { renewalUnsavedData }: any = useAppSelector((state) => state.renewal);

  const handleSidebarLinksClick = (param: string) => {
    if (Object.keys(renewalUnsavedData).length > 0) {
      dispatch(setRenewal({ renewalUnsavedModal: true }));
    } else {
      navigate(param);
    }
  };

  return (
    <div className={styles.sidebarMenu}>
      <ul className={styles.menu}>
        <MenuItem
          handleSidebarLinksClick={handleSidebarLinksClick}
          isActiveItem={pathname === '/home'}
          isSidebarOpen={isSidebarOpen}
          iconImg={prospect}
          label='Home'
          target='/home'
        />

        <MenuItem
          handleSidebarLinksClick={handleSidebarLinksClick}
          isActiveItem={pathname === '/goals'}
          isSidebarOpen={isSidebarOpen}
          iconImg={goalsIcon}
          label='Goals'
          target='/goals'
        />

        {userProducts && !!userProducts.length
          ? userProducts
              ?.filter(({ product }: any) => product !== 'Renewal+')
              ?.filter(({ product }: any) => product !== 'EasyApp')
              ?.map(
                (item: { product: string; iconURL: string }, index: number) => (
                  <MenuItem
                    key={`${item.product}-${index}`}
                    handleSidebarLinksClick={handleSidebarLinksClick}
                    isActiveItem={pathname === `/products/${item.product}`}
                    iconImg={item.iconURL}
                    isSidebarOpen={isSidebarOpen}
                    label={item.product}
                    target={`/products/${item.product}`}
                  />
                )
              )
          : ''}
      </ul>

      <ul className={styles.menu}>
        {roles &&
          roles?.find((record: any) => record.role_name === 'lux_admin') && (
            <MenuItem
              handleSidebarLinksClick={handleSidebarLinksClick}
              isActiveItem={pathname === '/admin'}
              isSidebarOpen={isSidebarOpen}
              iconImg={admin}
              label='Administration'
              target='/admin'
            />
          )}

        {userProperty && userProperty?.length > 0 && (
          <MenuItem
            handleSidebarLinksClick={handleSidebarLinksClick}
            isActiveItem={pathname === '/renewal'}
            isSidebarOpen={isSidebarOpen}
            iconImg={renewal}
            label='Renewal Plus'
            target='/renewal'
          />
        )}
      </ul>
    </div>
  );
};

export default Menu;
